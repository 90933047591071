<template>
  <v-skeleton-loader :loading="isSkeletonLoading" height="600" type="table">
    <v-data-table
      class="work-list-id-page"
      item-key="name"
      :headers="headers"
      :items="items"
      :search="search"
      :options.sync="options"
      :server-items-length="totals"
      :footer-props="tableFooterProps"
      :item-class="colorSelectedRow"
      @update:items-per-page="getWorkingList"
      @update:page="onChangePagination"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols sm="6" md="4">
            <v-text-field
              :label="$t('main.workList.search')"
              v-model="search"
              outlined
              dense
              v-on:keyup.enter="getWorkingList"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="isSearchAll"
              @change="getWorkingList"
              :label="$t('main.workList.allWorkList')"
              style="margin-top: 4px"
            />
          </v-col>
          <v-col cols>
            <v-row justify="end">
              <v-icon class="mr-1" color="warning"> mdi-circle </v-icon>
              <span class="subheading mr-2">{{
                $t("main.workList.status.waiting")
              }}</span>

              <v-icon class="mr-1" color="info"> mdi-circle </v-icon>
              <span class="subheading mr-2">{{
                $t("main.workList.status.inProgress")
              }}</span>

              <v-icon class="mr-1" color="success"> mdi-circle </v-icon>
              <span class="subheading mr-2">{{
                $t("main.workList.status.complete")
              }}</span>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.services`]="{ item }">
        <div class="d-flex flex-column">
          <v-tooltip right v-for="s in item.services" :key="s.id">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                :class="{
                  waiting: s.status === 1,
                  'in-progress': s.status === 2,
                  complete: s.status === 3,
                }"
                dark
                class="ma-1 justify-center"
                @click="openRoomTab(item.id, s.roomName, s.roomID)"
              >
                {{ s.name }}
              </v-chip>
            </template>
            <span>{{ s.tooltip }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.patientName`]="{ item }">
        <a @click="viewPatientInfo(item)">{{ item.patientName }}</a>
      </template>

      <template v-slot:[`item.admissionType`]="{ item }">
        {{ item.admissionTypeName }}
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <GenderItem :genderType="item.gender" />
      </template>

      <template v-slot:[`item.chief`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="chief-text"
              v-html="item.chief || 'None'"
            ></span>
          </template>
          <span v-html="item.chief || 'None'"></span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex flex-column">
          <v-chip
            :class="{
              dark: item.status === -1,
              waiting: item.status === 1,
              'in-progress': item.status === 2,
              complete: item.status === 3,
            }"
            class="ma-1 justify-center"
          >
            {{ getStatus(item.status) }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex flex-column">
          <v-chip
            :class="{
              waiting: item.status === 1,
              'in-progress': item.status === 2,
              complete: item.status === 3,
            }"
            dark
            class="ma-1 justify-center"
          >
            {{ getStatus(item.status) }}
          </v-chip>
        </div>
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
import { getNumberFromString, startOfDay, endOfDay } from "@/plugins/helper";
import { TABLE_FOOTER_PROPS } from "@/plugins/constant";
import WorkListService from "@/services/work-list";
import GenderItem from "@/components/GenderItem";
import moment from "moment";
import { UserTypeCodes } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

const STATUS = {
  1: "Waiting",
  2: "In progress",
  3: "Complete",
};
const HEADER_CHECKIN_BOOKING = [
  {
    text: "ID",
    align: "start",
    sortable: false,
    value: "id",
  },
  {
    text: i18n.t("main.workList.patientName"),
    align: "center",
    sortable: false,
    value: "patientName",
  },
  {
    text: i18n.t("main.workList.genderType"),
    align: "center",
    sortable: false,
    value: "gender",
  },
  {
    text: i18n.t("main.workList.dateOfBirth"),
    align: "center",
    sortable: false,
    value: "dob",
  },
  {
    text: i18n.t("main.workList.service"),
    align: "center",
    sortable: false,
    value: "services",
  },
  {
    text: i18n.t("main.workList.checkIn_Time"),
    align: "center",
    sortable: false,
    value: "checkin",
  },
  {
    text: i18n.t("main.workList.admissionType"),
    align: "center",
    sortable: false,
    value: "admissionType",
  },
  {
    text: i18n.t("main.workList.chiefComplaint"),
    align: "center",
    sortable: false,
    value: "chief",
  },
];
export default {
  name: "WorkListPage",
  components: {
    GenderItem,
  },
  data() {
    return {
      isSkeletonLoading: true,
      isSearchAll:
        this.$store.getters.userData.userTypeID == UserTypeCodes.Doctor
          ? false
          : true,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      totals: 0,
      items: [],
      headers: HEADER_CHECKIN_BOOKING,
      tableFooterProps: TABLE_FOOTER_PROPS,
    };
  },
  async mounted() {
    this.getWorkingList();
    this.sockets.common.on("OrderItemCompleted", this.onOrderItemCompleted);
    this.sockets.common.on("EpisodeCompleted", this.onEpisodeCompleted);
    this.sockets.common.on("EpisodeCanceled", this.onEpisodeCanceled);
    this.sockets.common.on(
      "NewPatientCheckedInClinic",
      this.onNewPatientCheckedInClinic
    );
  },
  methods: {
    showSuccess(message) {
      this.$toast.success(message);
    },
    onEpisodeCanceled() {
      this.getWorkingList(false);
    },
    onOrderItemCompleted(orderID) {
      orderID = parseInt(orderID);
      for (var item of this.items) {
        var ind = item.services.findIndex((service) => service.id == orderID);
        if (ind > -1) {
          item.services[ind].status = 3;
          this.showSuccess(
            i18n.t("message.workList.step") +
              `${item.services[ind].name}` +
              i18n.t("message.workList.isFinish")
          );
          return;
        }
      }
    },
    onEpisodeCompleted(episodeID) {
      episodeID = parseInt(episodeID);
      var ind = this.items.findIndex((ep) => ep.episodeID == episodeID);
      if (ind > -1) {
        var { patientName } = this.items[ind];
        this.showSuccess(
          i18n.t("message.workList.step") +
            `${patientName}` +
            i18n.t("message.workList.isFinish")
        );
        this.items.splice(ind, 1);
      }
    },
    onNewPatientCheckedInClinic(clinicID) {
      clinicID = parseInt(clinicID);
      if (clinicID == this.$store.getters.userData.clinicID) {
        var showLoading = false;
        this.getWorkingList(showLoading);
        this.showSuccess(i18n.t("message.workList.newPatientAlert"));
      }
    },
    getStatus(value) {
      return STATUS[value];
    },
    // function to render worklist by type
    async getWorkingList(isShowLoading = true) {
      var { isRoom, id } = this.getTypeWorkList();

      const keySearch = this.search;
      const statusID = "";
      const start = startOfDay();
      const end = endOfDay();

      const { page, itemsPerPage } = this.options;

      var apiResult;
      var doctorID = this.$store.getters.userData.userID;
      // if isRoom == true => get worklist by roomID
      this.isSkeletonLoading = isShowLoading;
      if (isRoom) {
        this.headers[5].text = "Check-in Time";
        apiResult = await WorkListService.SearchByRoomID(
          id,
          keySearch,
          statusID,
          start,
          end,
          page,
          itemsPerPage
        );
      }
      // else
      // typeID == 1 => get worklist by doctorID (user logged in)
      else if (id == 1) {
        this.headers[5].text = "Check-in Time";
        apiResult = await WorkListService.SearchByDoctorID(
          this.isSearchAll ? "" : doctorID,
          keySearch,
          statusID,
          start,
          end,
          page,
          itemsPerPage
        );
      }
      // type == 2 => get booking items today
      else {
        // get booking items
        this.headers[5].text = "Booking Time";
        apiResult = await WorkListService.SearchBookingByDoctorID(
          doctorID,
          keySearch,
          statusID,
          start,
          end,
          page,
          itemsPerPage
        );
      }
      this.isSkeletonLoading = false;
      if (apiResult.error) {
        return this.$toast.error(
          "Cannot get list work list at the moment, Please try again later!"
        );
      }
      var { items, totals } = apiResult;
      this.totals = totals;
      this.items = this.transformItems(items);
      this.updateTabTotals(totals);
    },
    updateTabTotals(total) {
      total = total || 0;
      var id = this.$route.params.id;
      this.$store.commit("UPDATE_WORKLIST_TAB_TOTAL", { id, total });
    },
    openRoomTab(itemID, name, roomID) {
      // if typeID == 2 (Booking Tab), then do nothing
      if (this.$route.params.id == 2) return;
      // else then proceed to open room with ID
      roomID = `r-${roomID}`;
      // if currently in this room
      if (this.$route.params.id == roomID) {
        // reload latest data
        return this.getWorkingList();
      }
      var payload = {
        id: roomID,
        title: name,
        total: 0,
      };
      this.$store.commit("ADD_WORKLIST_TAB", payload);
      // wait 50ms for store commit success
      setTimeout(() => {
        if (roomID != this.$route.params.id) {
          this.$router.push({
            path: `/home/work-list/${roomID}`,
            query: { id: itemID },
          });
        }
      }, 50);
    },
    // function to transform API result from server into actual worklist items
    transformItems(items) {
      return items.map((item) => {
        var {
          admissionInfo,
          episodeID,
          episodeRecordID,
          orders,
          patientInfo,
          bookingID,
        } = item;
        var { patientName, patientID, genderType, dob } = patientInfo;
        var {
          admissionTime,
          admissionTypeName,
          chiefComplaints,
        } = admissionInfo;
        // // transform order statusIDs
        // // find the first item with statusID == 1
        // var firstWaitingItemIndex = orders.findIndex((o) => o.statusID == 1);
        // if (firstWaitingItemIndex != -1) {
        //   // => convert into 2 == processing
        //   orders[firstWaitingItemIndex].statusID = 2;
        // }
        // epID or bookingID
        return {
          id: episodeRecordID || bookingID,
          isBookingWorkListItem: bookingID ? true : false,
          episodeID,
          patientName: patientName,
          patientID: patientID,
          gender: genderType,
          dob: moment(dob).format("DD/MM/YYYY"),
          checkin: moment(admissionTime).format("DD/MM/YYYY HH:mm"),
          services: orders.map((o) => {
            var roomName = `Room ${o.roomName}`;
            var staffName = `Staff ${o.staffPerformName}`;
            var tooltip = `${o.roomName} - ${o.staffPerformName}`;
            return {
              id: o.orderID,
              name: o.name,
              status: o.statusID,
              roomID: o.roomID,
              staffID: o.staffPerformID,
              roomName,
              staffName,
              tooltip,
            };
          }),
          admissionTypeName,
          chief: chiefComplaints || "None",
        };
      });
    },
    getTypeWorkList() {
      // if params.id is type room then id == "r1", "r2"...
      var isRoom = false;
      var id = this.$route.params.id || 1;
      if (id.includes("r")) {
        isRoom = true;
      }
      id = getNumberFromString(id);
      return {
        isRoom,
        id,
      };
    },
    async onChangePagination(value) {
      this.options.page = value;
      await this.getWorkingList();
    },
    handleKeySearchChange(e) {
      // search when user click enter
      if (e.keyCode === 13) {
        this.getWorkingList();
      }
    },
    colorSelectedRow(item) {
      return item.id == this.$route.query.id ? "blue lighten-5" : "";
    },
    viewPatientInfo(item) {
      var { patientID, patientName, isBookingWorkListItem, id } = item;
      var key = `/patient/${patientID}`;
      // if is EpisodeRecordID presented == not bookingID
      if (!isBookingWorkListItem) {
        // add the option to view this episode
        key += `?episodeRecordID=${id}`;
      }
      var tabData = {
        key,
        label: patientName,
      };
      parent.postMessage({ event: "ADD_HEADER_TAB", data: tabData }, "*");
    },
  },
  watch: {
    $route(value) {
      // call renderProduct
      if (value.path.includes("work-list")) {
        this.getWorkingList();
      }
    },
  },
};
</script>

<style lang="scss">
.work-list-id-page {
  margin-top: 24px;
}
.v-chip:before {
  pointer-events: all;
  cursor: pointer;
}
.chief-text {
  max-width: 200px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
}
</style>